import { clsx } from 'clsx';
import { useCallback } from 'react';
import { useController } from 'react-hook-form';

import type { FormFieldCurrencyProps } from './type';
import type { FieldPath, FieldValues } from 'react-hook-form';

import { FormFieldWrapper, InputNumber } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';
import { parseToNumber } from '@/utils/string';

import { formatToLocalString } from './util';

export const FormFieldCurrency = <
  T extends FieldValues,
  U extends FieldPath<T>,
>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  unit,
  ...props
}: FormFieldCurrencyProps<T, U>) => {
  const {
    field: { value, ...restField },
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const { wrapProps, rest: inputProps } = extractWrapperProps(props);

  /** NOTE: 返り値をNumber型へ変換 */
  const handleChange = useCallback(
    (input: string) =>
      restField.onChange(input.length > 0 ? parseToNumber(input) : null),
    [restField]
  );

  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      <div className={clsx('tw-w-full tw-flex tw-items-center tw-space-x-4')}>
        <InputNumber
          data-testid={name}
          id={name}
          value={value != null ? formatToLocalString(value) : ''}
          isRequired={wrapProps.isRequired}
          isInvalid={error !== undefined}
          {...inputProps}
          {...restField}
          onChange={handleChange}
        />
        <p className={clsx('tw-whitespace-nowrap')}>{unit}</p>
      </div>
    </FormFieldWrapper>
  );
};
