import { useMergeRefs } from '@chakra-ui/react';
import { Add, DeleteOutline } from '@mui/icons-material';
import { clsx } from 'clsx';
import { useMemo, useRef, useState, forwardRef } from 'react';

import type { InputFileProps } from './type';
import type { ChangeEventHandler, MouseEventHandler } from 'react';

import { Button, HeadlessButton, IconButton } from '@/components/ui';
import { API_ERROR_MESSAGE, VALIDATION_ERROR_MESSAGE } from '@/constants/error';
import { AVAILABLE_FILE_EXTENSIONS } from '@/constants/features/entry';
import { InputFileCreateFilePutUrlDocument } from '@/graphql/generated.anonymous';
import { useUploadFile } from '@/hooks/utils';
import { useMutationWrapper } from '@/libs/apollo';

import { isAccept, checkIsUnderFileSizeLimit } from './util';

export const InputFile = forwardRef<HTMLInputElement, InputFileProps>(
  (
    {
      value,
      placeholder = 'ファイルを追加',
      acceptList,
      maxFileSizeMb,
      isDisabled = false,
      isInvalid = false,
      children,
      onChange,
    },
    ref
  ) => {
    const [errorMessage, setErrorMessage] = useState<string>('');

    const inputFileRef = useRef<HTMLInputElement>(null);
    const refs = useMergeRefs(inputFileRef, ref);

    const [createFilePutUrl, { loading }] = useMutationWrapper(
      InputFileCreateFilePutUrlDocument,
      { role: 'anonymous' }
    );

    const fileUploadMutation = useUploadFile();

    const accept = useMemo(() => {
      return acceptList?.join(',');
    }, [acceptList]);

    const isImageFile = useMemo(() => {
      if (value == null || !value.url) return false;
      const urlChunks = value.url.split('.');
      const extension = urlChunks.pop();
      if (extension === undefined) return false;
      return AVAILABLE_FILE_EXTENSIONS.IMAGE.map(String).includes(
        extension.toLowerCase()
      );
    }, [value]);

    /** ファイル選択ボタン押下処理 */
    const handleClickSelectButton: MouseEventHandler<HTMLButtonElement> = (
      e
    ) => {
      if (inputFileRef?.current) {
        inputFileRef.current.click();
      }
      e.stopPropagation();
    };

    /** ファイル選択時の処理 */
    // HACK: createFileUploadUrlの中でonChangeしているが、asyncが無いと通信を待たずにonChangeしてしまう
    const handleChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
      setErrorMessage('');

      if (!e.target.files || e.target.files.length === 0) {
        return;
      }

      if (!isAccept({ files: e.target.files, acceptList })) {
        handleInvalidFormatFile();
        return;
      }

      if (
        maxFileSizeMb !== undefined &&
        !checkIsUnderFileSizeLimit(e.target.files[0], maxFileSizeMb)
      ) {
        handleInvalidSizeFile(maxFileSizeMb);
        return;
      }

      createFileUploadUrl(e.target.files[0]);

      // HACK: 同じファイルを連続して選択できるようにする
      e.target.value = '';
    };

    /** ファイルアップロード先認証付きURLの取得処理 */
    const createFileUploadUrl = (file: File) => {
      createFilePutUrl({
        variables: { fileName: file.name },
        onCompleted: ({ generateUrlForPutFile: { url } }) => {
          uploadFile(url, file);
        },
        onError: () => {
          setErrorMessage(API_ERROR_MESSAGE.UNKNOWN);
        },
      });
    };

    /** ファイルアップロード処理 */
    const uploadFile = (url: string, file: File) => {
      fileUploadMutation.execute({
        url,
        file,
        onSuccess: () => {
          const [urlWithoutQueryString] = url.split('?');
          onChange({ name: file.name, url: urlWithoutQueryString });
        },
        onError: () => {
          setErrorMessage(API_ERROR_MESSAGE.UNKNOWN);
        },
      });
    };

    /** 拡張子エラー時の処理 */
    const handleInvalidFormatFile = () => {
      setErrorMessage(VALIDATION_ERROR_MESSAGE.FILE_FORMAT);
      onChange(null);
    };

    /** ファイルサイズエラー時の処理 */
    const handleInvalidSizeFile = (fileSizeLimitMb: number) => {
      setErrorMessage(VALIDATION_ERROR_MESSAGE.FILE_SIZE(fileSizeLimitMb));
      onChange(null);
    };

    const handleClearSelectedFile: MouseEventHandler<HTMLButtonElement> = (
      e
    ) => {
      onChange(null);
      setErrorMessage('');
      e.stopPropagation();
    };

    return (
      <div className={clsx(isDisabled && 'tw-cursor-not-allowed')}>
        {children ? (
          <HeadlessButton
            disabled={isDisabled}
            onClick={handleClickSelectButton}
          >
            {children}
          </HeadlessButton>
        ) : (
          <>
            {value ? (
              <>
                {isImageFile && (
                  <a href={value.url} target="_blank" rel="noreferrer">
                    <img
                      src={value.url}
                      alt=""
                      className={clsx(
                        'tw-mb-2 tw-max-w-[320px] tw-max-h-[320px] tw-object-scale-down'
                      )}
                    />
                  </a>
                )}
                <button
                  type="button"
                  disabled={isDisabled}
                  onClick={handleClickSelectButton}
                  className={clsx(
                    'tw-h-12 tw-w-full tw-pl-4 tw-pr-2',
                    'tw-flex tw-items-center tw-justify-between tw-space-x-2',
                    'tw-border tw-border-gray-400 tw-rounded',
                    isDisabled && 'tw-cursor-not-allowed'
                  )}
                >
                  <p
                    className={clsx(
                      'tw-flex-1 tw-text-left tw-line-clamp-1 tw-break-all',
                      isDisabled && 'tw-text-gray-500'
                    )}
                  >
                    {value.name}
                  </p>
                  <IconButton
                    icon={<DeleteOutline fontSize="inherit" />}
                    as="div"
                    aria-label="選択したファイルを削除"
                    disabled={isDisabled}
                    onClick={handleClearSelectedFile}
                  />
                </button>
              </>
            ) : (
              <span
                className={clsx(
                  isInvalid &&
                    'tw-outline tw-outline-2 tw-outline-red-500 tw-rounded-md'
                )}
              >
                <Button
                  type="button"
                  theme="outline"
                  leftIcon={<Add fontSize="inherit" />}
                  isDisabled={isDisabled}
                  isLoading={loading || fileUploadMutation.loading}
                  isBlock={false}
                  onClick={handleClickSelectButton}
                >
                  {placeholder}
                </Button>
              </span>
            )}
          </>
        )}
        <input
          type="file"
          hidden={true}
          ref={refs}
          onChange={handleChange}
          disabled={isDisabled}
          accept={accept}
          multiple={false}
        />
        {errorMessage && (
          <p
            role="alert"
            aria-label={errorMessage}
            className={clsx('tw-text-sm tw-text-red-500 tw-mt-2')}
          >
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
);

InputFile.displayName = 'InputFile';
