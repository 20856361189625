import { EntryFormFieldTypeEnum } from '@/graphql/generated';

export const ALL_FORM_FIELD_TYPES = [
  EntryFormFieldTypeEnum.Text,
  EntryFormFieldTypeEnum.Textarea,
  EntryFormFieldTypeEnum.Url,
  EntryFormFieldTypeEnum.Checkbox,
  EntryFormFieldTypeEnum.Radio,
  EntryFormFieldTypeEnum.Selectbox,
  EntryFormFieldTypeEnum.Image,
  EntryFormFieldTypeEnum.File,
  EntryFormFieldTypeEnum.Group,
] as const;

export const ALL_FORM_PRECAUTION_TYPES = [
  'analog_painting',
  'manuscript_pdf',
] as const;

export const ALL_FORM_PRECAUTION_LABELS = {
  analog_painting: '絵画・イラストの撮影について',
  manuscript_pdf: '手書き原稿のPDF化について',
} satisfies Record<(typeof ALL_FORM_PRECAUTION_TYPES)[number], string>;

export const AVAILABLE_FILE_EXTENSIONS = {
  IMAGE: ['jpg', 'jpeg', 'png', 'gif', 'webp'] as const,
  FILE: [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'webp',
    'txt',
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'ai',
    'psd',
    'clip',
    'mov',
    'mp4',
    'webm',
    'ogg',
    'avi',
    'wav',
    'aif',
    'mp3',
    'aac',
    'flac',
    '3ds',
    'max',
    'ma',
    'mb',
    'blend',
    'zip',
  ] as const,
};
