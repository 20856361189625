import { Skeleton } from '@chakra-ui/react';
import { clsx } from 'clsx';
import NextImage from 'next/image';
import { useCallback, useState } from 'react';

import type { ImageProps } from './type';
import type { ReactEventHandler } from 'react';

import { NO_IMAGE_SRC, OBJECT_FIT_CLASS_NAMES } from './const';
import { loader, generateSizes } from './util';

export const Image = ({
  src,
  alt = '',
  fill = true,
  sizes,
  objectFit = 'scale-down',
  priority = false,
  size,
  unoptimized = false,
  className,
  style,
}: ImageProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleError: ReactEventHandler<HTMLImageElement> = useCallback(
    (event) => {
      const imageElement = event.target as HTMLImageElement;
      imageElement.onerror = null;
      imageElement.src = NO_IMAGE_SRC;
      setIsLoading(false);
    },
    []
  );

  const handleLoadingComplete = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {isLoading && (
        <Skeleton
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          zIndex="1"
          startColor="blackAlpha.200"
          endColor="blackAlpha.300"
        />
      )}
      <NextImage
        src={src || NO_IMAGE_SRC}
        alt={alt}
        fill={fill}
        priority={priority}
        className={clsx(
          '!tw-static',
          OBJECT_FIT_CLASS_NAMES(objectFit),
          className
        )}
        style={{
          ...style,
          visibility: isLoading ? 'hidden' : 'visible',
        }}
        sizes={sizes ?? generateSizes(size)}
        unoptimized={loader ? unoptimized : true}
        onLoadingComplete={handleLoadingComplete}
        onError={handleError}
        loader={loader}
      />
    </div>
  );
};
